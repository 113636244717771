
import "@mdi/font/scss/materialdesignicons.scss";
import "./css/label/style.scss"
import 'popper.js'
import 'bootstrap'
import 'paper-template/components/_toastr'
import 'label/template'
import 'label/high-contrast-toggle'

import './css/devise.scss'
import './css/test_passages.scss'

import 'knockout/init'

import 'knockout/test_passage'
import 'knockout/custom_test_passage'
import 'knockout/bpaq24_test_passage'
import 'knockout/s_survey_test_passage'
import 'knockout/d_survey_test_passage'
import 'knockout/pbos_test_passage'
import 'knockout/avr_test_passage'
import 'knockout/gpv_test_passage'

$(document).on('change', '#test-passage-range', function (event) {
  const value = $("#test-passage-range-value")
  value.innerHTML = event.target.value
  selectAnswer(event.target.value)
})
