import ko from 'knockout';
import $ from 'jquery';

const dSurveyQuestionCache = {}

class DSurveyQuestion {
    constructor(data) {
        this.id = data.id
        this.number = data.number
        this.answers = data.answers
        dSurveyQuestionCache[this.id] = this
    }
}

class DSurveyAnswer {
    constructor(data) {
        if (Array.isArray(data)) { // загружаем из начальных данных
            this.question_id = data[0]
            this.answer = data[1]
            this.duration = data[2]
            this.answered_at = data[3]
        } else { // используем внутри
            this.question_id = data.question_id
            this.answer = data.answer
            this.duration = data.duration
            this.answered_at = data.answered_at || Date.now()
        }
    }

    serialize() { // обмениваемся массивами, так как это намного быстрее
        return [
            this.question_id,
            this.answer,
            this.duration,
            this.answered_at
        ]
    }
}

class DSurveyPassage {
    constructor(data) {
        this.id = data.id
        this.url = `/d_survey_passages/${this.id}`

        this.questions = ko.observableArray(data.questions.map((item) => new DSurveyQuestion(item)))

        const localData = localStorage.getItem(this.localStorageKey());
        const localAnswers = localData && JSON.parse(localData);

        this.completeFromLocalStorage = false;
        let chosen = data.answers;
        if (localAnswers && localAnswers.answers && localAnswers.answers.length > data.answers.length) {
            chosen = localAnswers.answers;

            // если обрыв связи был на последнем вопросе, то надо сохраниться
            this.completeFromLocalStorage = chosen.length === data.questions.length;
        }

        this.answers = ko.observableArray(chosen.map((item) => new DSurveyAnswer(item)))

        this.answered = ko.observableArray([])

        for (const a of chosen) {
            const q = dSurveyQuestionCache[a[0]]
            this.questions.remove(q)

            this.answered().push(q)
        }

        this.currentQuestion = ko.observable(null)

        this.currentQuestionNumber = ko.computed(() => this.answers().length + 1)
        this.selectedAnswer = ko.observable(null)

        this.currentQuestionAnswers = ko.computed(() => this.currentQuestion() ? this.currentQuestion().answers : [])

        this.view = ko.observable('instruction')
        if (this.currentQuestionNumber() > 1) {
            this.passage()
        }
    }

    selectAnswer(answer) {
        this.selectedAnswer(answer)
    }

    confirmAnswer() {
        if (this.selectedAnswer() == null) {
            return;
        }
        this.selectAndConfirmAnswer(this.selectedAnswer());
        this.selectedAnswer(null);
    }

    selectAndConfirmAnswer(answerValue) {
        const question = this.currentQuestion();
        const answer = new DSurveyAnswer({
            question_id: question.id,
            answer: answerValue,
            duration: Math.floor((performance.now() - this.startQuestionTime)/1000)
        });
        this.answers.push(answer);
        this.answered.push(question);
        this.questions.remove(question);
        this.saveAnswers();

        this.passage()
    }

    nextQuestion() {
        const count = this.questions().length;

        if (count > 0) {
            return this.questions()[0]
        } else {
            return undefined;
        }
    }

    passage() {
        const nextQuestion = this.nextQuestion()

        if (nextQuestion) {
            this.currentQuestion(nextQuestion)
            this.startQuestionTime = performance.now()
            this.view('question')
        } else {
            this.view('completed')
            if (this.completeFromLocalStorage) {
                this.saveAnswers();
            }
        }
    }

    localStorageKey() {
        return `dSurveyPassage#${this.id}`;
    }

    saveAnswers() {
        const url = this.url;
        const answers_array = this.answers().map(a => a.serialize());
        const data = JSON.stringify({answers: answers_array, type: 'd_survey'});

        $.ajax({
            url: url,
            method: 'PATCH',
            data: data,
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
        }).done((data) => {
            localStorage.removeItem(this.localStorageKey());

            if (data.completed) {
                window.location = url
            }
        }).fail(() => {
            localStorage.setItem(this.localStorageKey(), data);
            this.view('saveFailed');
        });
    }
}

ko.startDSurveyPassage = function (data, questions) {
    data.questions = questions

    ko.applyBindings(new DSurveyPassage(data))

    $('#no-ko-view').hide()
    $('#ko-view').show()
}
