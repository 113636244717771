import ko from 'knockout';
import $ from 'jquery';

const questionCache = {}

class Question {
    constructor(data) {
        this.id = data.id
        this.num = data.num
        this.content = data.content
        questionCache[this.id] = this
    }
}

class Answer {
    constructor(data) {
        if (Array.isArray(data)) { // загружаем из начальных данных
            this.question_id = data[0]
            this.answer = data[1]
            this.duration = data[2]
            this.postponed_at = data[3]
            this.answered_at = data[4]
        } else { // используем внутри
            this.question_id = data.question_id
            this.answer = data.answer
            this.duration = data.duration
            this.postponed_at = data.postponed_at
            this.answered_at = data.answered_at || Date.now()
        }
    }

    serialize() { // обмениваемся массивами, так как это намного быстрее
        return [
            this.question_id,
            this.answer,
            this.duration,
            this.postponed_at,
            this.answered_at
        ]
    }
}

class TestPassage {
    MONOTONY_SIZE = 13;

    constructor(data) {
        this.id = data.id
        this.url = `/test_passages/${this.id}`

        this.data_questions = data.questions;
        this.questions = ko.observableArray(data.questions.map((item) => new Question(item)))

        const localData = localStorage.getItem(this.localStorageKey());
        const localAnswers = localData && JSON.parse(localData);

        this.completeFromLocalStorage = false;
        let chosen = data.answers;
        if (localAnswers && localAnswers.answers && localAnswers.answers.length > data.answers.length) {
            chosen = localAnswers.answers;

            // если обрыв связи был на последнем вопросе, то надо сохраниться
            this.completeFromLocalStorage = chosen.length === data.questions.length;
        }

        this.answers = ko.observableArray(chosen.map((item) => new Answer(item)))

        this.postponed = ko.observableArray([])
        this.answered = ko.observableArray([])

        for (const a of chosen) {
            const q = questionCache[a[0]]
            this.questions.remove(q)

            if (a[3]) { // postponed_at
                this.postponed().push(q)
            } else {
                this.answered().push(q)
            }
        }

        this.currentQuestion = ko.observable(null)

        this.postponedCount = ko.computed(() => this.postponed().length)
        this.currentQuestionNumber = ko.computed(() => this.answers().length + 1)
        this.currentQuestionContent = ko.computed(() => this.currentQuestion() ? this.currentQuestion().content : '')
        this.selectedAnswer = ko.observable(null)

        this.view = ko.observable('instruction')
        if (this.currentQuestionNumber() > 1) {
            this.passage()
        }
    }

    activeIfSelected(answer) {
        this.selectedAnswer() == answer ? 'active' : 'inactive'
    }

    selectAnswer(answer) {
        this.selectedAnswer(answer)
    }

    confirmAnswer() {
        if (this.selectedAnswer() == null) {
            return;
        }
        this.selectAndConfirmAnswer(this.selectedAnswer());
        this.selectedAnswer(null);
    }

    answerPostponed() {
        this.questions.push(...this.postponed())
        this.answers.remove((a) => a.postponed_at)
        this.postponed.removeAll()
        this.passage()
    }

    restartMonotone() {
        this.questions = ko.observableArray(this.data_questions.map((item) => new Question(item)))
        this.answers.removeAll()
        this.postponed.removeAll()
        this.view('too_monotone')
    }

    selectAndConfirmAnswer(answerValue) {
        const question = this.currentQuestion();
        const answer = new Answer({
            question_id: question.id,
            answer: answerValue,
            duration: Math.floor((performance.now() - this.startQuestionTime)/1000)
        });
        this.answers.push(answer);
        this.answered.push(question);
        this.questions.remove(question);

        const monotony_check = this.answers.slice(-this.MONOTONY_SIZE).map(a => a.answer);
        const uniques = [...new Set(monotony_check)];
        if (monotony_check.length >= this.MONOTONY_SIZE && uniques.length === 1) {
            this.restartMonotone();
            return
        } else {
            this.saveAnswers();
        }

        this.passage()
    }

    getRandom(max) {
        return Math.floor(Math.random() * max);
    }

    randomQuestion() {
        const count = this.questions().length;

        if (count > 0) {
            return this.questions()[this.getRandom(count)]
        } else {
            return undefined;
        }
    }

    postponeQuestion() {
        const question = this.currentQuestion();

        this.questions.remove(question)
        this.postponed.push(question)

        const answer = new Answer({
            question_id: question.id,
            postponed_at: Date.now(),
        });
        this.answers.push(answer)

        this.saveAnswers()

        this.passage()
    }

    passage() {
        const nextQuestion = this.randomQuestion()

        if (nextQuestion) {
            this.currentQuestion(nextQuestion)
            this.startQuestionTime = performance.now()
            this.view('question')
        } else if (this.postponedCount() > 0) {
            this.view('postponed')
        } else {
            this.view('completed')
            if (this.completeFromLocalStorage) {
                this.saveAnswers();
            }
        }
    }

    localStorageKey() {
        return `testPassage#${this.id}`;
    }

    saveAnswers() {
        const url = this.url;
        const answers_array = this.answers().map(a => a.serialize());
        const data = JSON.stringify({answers: answers_array, type: 'test'});

        $.ajax({
            url: url,
            method: 'PATCH',
            data: data,
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
        }).done((data) => {
            localStorage.removeItem(this.localStorageKey());

            if (data.completed) {
                window.location = url
            }
        }).fail(() => {
            localStorage.setItem(this.localStorageKey(), data);
            this.view('saveFailed');
        });
    }
}

ko.startTestPassage = function (data, questions) {
    data.questions = questions
    const passage = new TestPassage(data)
    ko.applyBindings(passage)

    $('#no-ko-view').hide()
    $('#ko-view').show()
}
